import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { addDays } from 'date-fns';
import es from 'date-fns/locale/es';
import InputGroup from 'react-bootstrap/InputGroup'
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from 'react-bootstrap/Spinner';
import jwt from 'jsonwebtoken';

// Capcha keys: site "6LeLKb4ZAAAAAKkyS6Ec_hf6OMdkj-Q4kxeWjpIl" secret: "6LeLKb4ZAAAAAMXU_sIHrk5ll6ct7-2b43BPHafo"

registerLocale("es-CO", es);

class ParkingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parkingSelected: null,
        };
    }

    makeId(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    sendSMS(to, username, password, content) {
        const messagePayload = {
            t: to,
            u: username,
            p: password,
            c: content
        };

        const messageSignOptions = {
            issuer: 'Intellectco S.A.S',
            subject: 'soporte@intellectco.com.co',
            audience: 'https://intellectco.com.co',
            expiresIn:  "12h",
            algorithm:  "RS256"
        }

        const token = jwt.sign(messagePayload, this.props.privateKey, messageSignOptions);
        
        var data = new FormData();
        data.append('payload', token);

        fetch(this.props.urlSMPP,
            {
                mode: 'no-cors',
                method: "POST",
                body: data
            })
        .then(res => console.log(res));
    }

    render() {
        const inputCarPlate = {
            backgroundColor: "#e7e7e7",
            textAlign: "center"
        }

        const handleSubmit = (event) => {
                    this.props.setLoading(true);
            event.preventDefault();
            event.stopPropagation();
            const form = event.currentTarget;
                    const recaptchaValue = this.props.googleRecaptchaRef.current.getValue();
                    if (form.checkValidity() === true && this.state.parkingSelected && recaptchaValue && this.props.bookingDate.getTime() > new Date().getTime()) {
                const bookingId = this.makeId(5);
                this.props.setBookingId(bookingId);
                        const clientMessage = this.state.parkingSelected.clientMessageTemplate.replace('{bookingId}', bookingId);
                        const parkingMessage = this.state.parkingSelected.parkingMessageTemplate
                            .replace('{bookingId}', bookingId)
                    .replace('{clientName}', form[0].value)
                    .replace('{telphone}', form[1].value)
                    .replace('{plate}', form[2].value.toUpperCase())
                    .replace('{date}', this.props.bookingDate.toLocaleDateString('es-CO') + '-' + this.props.bookingDate.toLocaleTimeString('es-CO'));
                this.sendSMS('57' + form[1].value, this.props.smsUser, this.props.smsPass, clientMessage);
                this.sendSMS('57' + this.state.parkingSelected.parkingNumber, this.props.smsUser, this.props.smsPass, parkingMessage);
                this.props.handleClose();
                this.props.setCompleted(true);
            }
            this.props.setLoading(false);
        }
        const rows = [...Array(Math.ceil(this.props.data.length / 4))];
                const cardRows = rows.map((row, idx) => this.props.data.slice(idx * 4, idx * 4 + 4));
                const content = cardRows.map((row, idx) => (
                    <Row xs={1} md={1} lg={1} xl={1}>
                {row.map(parkingSlot =>
                    <Col>
                        <Card className="mx-auto">
                            <Card.Img src={parkingSlot.image} />
                            <Card.Body className="text-center">
                                <Card.Title>{parkingSlot.name}</Card.Title>
                                <Card.Text>{parkingSlot.Address}</Card.Text>
                                <Button onClick={() => {
                                    this.props.handleShow();
                                    this.state.parkingSelected = parkingSlot;
                                }} className="shadow">¡Agenda ya!</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        ));
        return (
            <div>
                <Container className="text-center">
                    {content}
                </Container>
                <Modal aria-labelledby="contained-modal-title-vcenter" centered show={this.props.show} onHide={this.props.handleClose}>
                    <Modal.Header>
                        <Modal.Title className="my-auto w-100 text-center">Agendamiento de parqueadero</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Nombre del conductor</Form.Label>
                                <Form.Control size="lg" type="text" required minLength="3" />
                                <Form.Control.Feedback type="invalid">
                                    Favor indicar nombre del conductor.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formTel">
                                <Form.Label>Teléfono celular</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">+57</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control size="lg" type="tel" required pattern="[0-9]{10}" />
                                    <Form.Control.Feedback type="invalid">
                                        favor teléfono celular valido.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="formPlate">
                                <Form.Label>Placa del vehículo</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="XXX000" className="text-uppercase" style={inputCarPlate} required />
                                <Form.Control.Feedback type="invalid">
                                    favor placa introducir.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formDate">
                                <Form.Label>Fecha y hora de agendamiento</Form.Label>
                                <div className="text-center">
                                    <DatePicker
                                        selected={this.props.bookingDate}
                                        onChange={date => this.props.setStartDate(date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={30}
                                        timeCaption="Hora"
                                        locale="es-CO"
                                        dateFormat="Pp"
                                        timeFormat="p"
                                        maxDate={addDays(new Date(), 7)}
                                        minDate={new Date()}
                                        inline
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="reCapcha" className="text-center">
                                <div className="text-center">
                                    <ReCAPTCHA
                                        ref={this.props.googleRecaptchaRef}
                                        sitekey={this.props.googleCaptchaSiteKey}
                                        className="g-recaptcha"
                                    />
                                </div>
                            </Form.Group>
                            <Button variant="secondary" className="float-left text-uppercase" onClick={this.props.handleClose}>Cerrar</Button>
                            <Button variant="primary" className="float-right text-uppercase" style={{ backgroundColor: "#00528d" }} type="submit" disabled={this.props.loading}>
                                {this.props.loading ?
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" />
                                    :
                                    'Agendar'}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal aria-labelledby="contained-modal-title-vcenter" centered show={this.props.completed} onHide={this.props.handleCompleted}>
                    <Modal.Header closeButton>
                        <Modal.Title className="my-auto w-100 text-center">Reserva confirmada</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Su número de reserva es: <b>{this.props.bookingId}</b>, un agente de servicio te contactará en breve y recibiras una confirmación por mensaje de texto.
                        </p>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ParkingList;