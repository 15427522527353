import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

class Banner extends React.Component {
    render() {
        return (
            <div>
                <Container className="central-banner-container">
                    <Row className="h-100 transbox" />
                </Container>
                <br />
                <Container>
                    <Row className="under-banner-textbox">
                        <Col className="my-auto">
                            <div className="text-center central-text">
                                <h2>reserva tu parqueadero</h2>
                                <h4>solo escoge el día, la hora</h4>
                                <h4>¡y listo!</h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Banner;

/*                  <Row className="h-100 transbox">
<Col className="my-auto">
<div className="text-center central-text">
    <h4>RESERVA TU PARQUEADERO</h4>
    <h1>SOLO ESCOGE EL DÍA LA HORA</h1>
    <h1>¡Y LISTO!</h1>
</div>
</Col>
</Row>*/