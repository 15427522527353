import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import GeoParqueaderosLogo from '../images/LOGO_GEO_PARQUEADERO-01.png'
import Container from 'react-bootstrap/Container';

class Header extends React.Component {
    render() {
        return (
            <header>
                <Container>
                    <Navbar>
                        <Navbar.Brand href="/" className="">
                            <img
                                src={GeoParqueaderosLogo}
                                alt="GeoParqueaderos"
                                className="d-inline-block my-auto logo"
                            />
                        </Navbar.Brand>
                    </Navbar>
                </Container>
            </header>
        );
    }
}

export default Header;