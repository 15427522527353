import React, { useState } from 'react';
import Header from './components/Header';
import Banner from './components/Banner';
import ParkingList from './components/ParkingList';
import Carulla from './images/carulla san fernando-100.jpg';
import SuperInter from './images/super inter san fernando-100.jpg';

const data =
  [
    {
      id: 1,
      name: "Super inter san fernando",
      Address: "Calle 4 # 27-161",
      image: SuperInter,
      parkingNumber: "3104110525",
      clientMessageTemplate: "GEO Parqueadero: Reserva {bookingId} confirmada, en un momento un agente de servicio te contactara. Aqui puedes ver como llegar https://waze.com/ul/hd29ed1nqu",
      parkingMessageTemplate: "Reserva {bookingId}, Usuario: {clientName}, Celular: {telphone}, Fecha: {date}, placa: {plate} / Super inter san fernando"
    }/*,
    {
      id: 2,
      name: "Carulla san fernando",
      Address: "Calle 4b # 34-04",
      image: Carulla,
      parkingNumber: "3203023582",
      clientMessageTemplate: "GEO Parqueadero: Reserva {bookingId} confirmada, en un momento un agente de servicio te contactara. Aqui puedes ver como llegar https://waze.com/ul/hd29ed1n5u",
      parkingMessageTemplate: "Reserva {bookingId}, Usuario: {clientName}, Celular: {telphone}, Fecha: {date}, placa: {plate} / Carulla san fernando"
    },*/
  ];

  
function App() {
  const [show, setShow] = useState(false);
  const [bookingId, setBookingId] = useState('');
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [completed, setCompleted] = useState(false);
  const handleCompleted = () => setCompleted(false)
  const [bookingDate, setStartDate] = useState(new Date());

  const googleCaptchaSiteKey = "6LeLKb4ZAAAAAKkyS6Ec_hf6OMdkj-Q4kxeWjpIl";
  const smsUser = "geo";
  //const smsUser = "parkcont";
  const smsPass = "88597172";       
  const privateKey = "-----BEGIN RSA PRIVATE KEY-----\n" +
  "MIICXQIBAAKBgQCq7nzav7lEXROQXuag7dNQzM++c3hpy0cIk1xJrkB5XpgPMJqR\n" +
  "QZB8TKYeoqCiD7HF+PcKMUIVqqndNhRDImQHL0QSk3xvYGJRgDRBvvu6MrS8KqDH\n" +
  "wR2IDBDyiBoQ/8eA3fceSFJSiOQlnkEjhk4Oo9BPVaBbkoyOKCPvzbIHmQIDAQAB\n" +
  "AoGAOEodV7Q3gKneHXcDIA/xvPQVnbRM/RnyJILSq3j0xoM+NBJfdGJRAH4FXqVT\n" +
  "nMrqUjWwtOyPxK7VDYIXnhwfMCxxDOd6L720SWaMJKRs7uMRG2d44CanJpnH4UPR\n" +
  "hWoNlsuKrYtn4JNUtoFkyJQyNsAWHnXS1HcQ9cFXotsNOPkCQQDfRUMUYQEJOK37\n" +
  "A38e3+/j5b3L9Dw14wgt7Davyzze78ijwEbZuRKGHtbuJiABaLnCfmwZldlq3Tlu\n" +
  "d/UWHSY7AkEAw/0WI4WIGv/lY/WUbdfzBG+ARc746ieHkRuQaS2HrC60HB2KF67E\n" +
  "d+bm2VhpEVRSjTvLaraqjqzgsLAUx/MoOwJBANjjv7YcRZVfqiMd0m97ut34X9ew\n" +
  "+oyeb2OtA7LiC8/Fv3LTZgA6+POxIblW7+Bui4WUqg5wszRMk23sufkxd+ECQH1/\n" +
  "GOcEAUQwqcVZIoM3rXAgqcWJo4/kYgWD3fPLaL3TZhc+vwctLLcOTrHLz/N9yCKR\n" +
  "sE6TyyDzCCl7hsMGK7MCQQC0uh6Qs/AXeGwuzcnDRdI+AyD5h1g6Sqe9jbEJFuiH\n" +
  "WQZvuUliet10IYOf02EaDVDRW8ZutVV+Z21fTdjoIiBj\n" +
  "-----END RSA PRIVATE KEY-----";
  const urlSMPP = "https://smpp.azurewebsites.net/SMPP.php"
  //const urlSMPP = "http://localhost:8080/SMPP.php"
  const recaptchaRef = React.createRef();

  return (
    <div>
      <Header />
      <Banner />
      <ParkingList
        data={data}
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
        bookingDate={bookingDate}
        setStartDate={setStartDate} 
        googleCaptchaSiteKey={googleCaptchaSiteKey}
        googleRecaptchaRef={recaptchaRef}
        smsUser={smsUser}
        smsPass={smsPass}
        loading={loading}
        completed={completed}
        setLoading={setLoading}
        setCompleted={setCompleted}
        handleCompleted={handleCompleted} 
        privateKey={privateKey}
        urlSMPP={urlSMPP}
        bookingId={bookingId}
        setBookingId={setBookingId}/>
    </div>

  );
}

export default App;
